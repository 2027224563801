<!--管理员平台-->
<template>
    <div class="common">
        <!--顶部-->
        <el-row type="flex" justify="space-between" align="middle" class="commonTop" :style="{'left':isCollapse?'64px':'200px'}">
            <el-row type="flex" align="middle" class="name">
                <router-link :to="{ name: '统计概况'}">织脉数字化商城连锁版后台管理系统</router-link>
            </el-row>
            <el-row type="flex" justify="space-between" align="middle" class="nav">
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-s-help"></i>
                        <span style="margin-left:5px">公众号</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/gzh.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-shopping-bag-1"></i><span style="margin-left:5px">小程序</span><i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/codes.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-truck"></i><span style="margin-left:5px">配送APP</span><i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/app.png')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <router-link :to="{ name: '会员提现审核'}">
                    <div class="msg flex flex-ai-c" style="cursor: pointer;">
                        <p>消息通知</p>
                        <el-badge :value="$root.auditCount.count == 0 ? '' : $root.auditCount.count" class="item">
                            <img src="../assets/laba2.png" alt="">
                        </el-badge>
                    </div>
                </router-link>

                <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{adminInfo.username}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="loginOut()">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
            </el-row>
        </el-row>

        <!--左侧菜单-->
        <el-row class="commonLeft" :style="{'width':isCollapse?'64px':'200px'}">
            <el-row class="nav">
                <el-aside>
                    <el-row type="flex" justify="center" class="headImg">
                        <img :src="require('../assets/avatars.png')" :style="{'width':isCollapse?'42px':'60px'}" />
                        <p v-if="!isCollapse">{{adminInfo.username}}</p>
                    </el-row>
                    <el-menu background-color="#001529" text-color="#EBF2FF" :default-active="$route.path" :unique-opened="true" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 184px );overflow-y: auto;overflow-x: hidden;">
                        <template v-for="nav in navList2">
                            <el-menu-item :index="nav.path" :key="nav.name" _click="navTo(nav.path)" v-if="!nav.sub">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span slot="title">{{nav.name}}</span>
                            </el-menu-item>

                            <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                                <template slot="title">
                                    <i :class="nav.icon" v-if="nav.icon"></i>
                                    <span>{{nav.name}}</span>
                                </template>
                                <template v-for="item in nav.sub">
                                    <el-menu-item :index="item.path" :key="item.name" v-if="!item.sub">
                                        <i :class="item.icon" v-if="item.icon"></i>
                                        {{item.name}}
                                    </el-menu-item>

                                    <el-submenu :index="item.path" :key="item.name" v-if="item.sub">
                                        <template slot="title">
                                            <i :class="item.icon" v-if="item.icon"></i>
                                            <span>{{item.name}}</span>
                                        </template>
                                        <el-menu-item v-for="item2 in item.sub" :index="item2.path" :key="item2.name">
                                            <i :class="item2.icon" v-if="item2.icon"></i>
                                            {{item2.name}}
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                            </el-submenu>
                        </template>
                    </el-menu>
                    <el-row class="menuBtnbox" :style="{'width':isCollapse?'64px':'200px'}">
                        <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                    </el-row>
                </el-aside>
            </el-row>
        </el-row>

        <!--主要内容-->
        <el-row class="commonRight" :style="{'left':isCollapse?'64px':'200px'}">
            <router-view :isCollapse="isCollapse" />
        </el-row>
    </div>
</template>

<script>
import { backPlatformApi } from '@/api/house.js';
import axios from 'axios';
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息 
export default {
    data: function () {
        return {
            defaultActive: this.$route.path,
            isCollapse: false,
            transitionName: '',
            adminInfo: {},
            navList2: [{
                name: '统计概况',
                path: '/overview',
                icon: 'el-icon-s-home'
            },{
                name: '各店统计',
                path: '/sale',
                icon: 'el-icon-s-data',
                sub: [{
                    name: '销售统计',
                    path: '/sale',
                }, {
                    name: '销售排行',
                    path: '/salesRanking',
                }, {
                    name: '销售对比',
                    path: '/salesStatistics',
                }, {
                    name: '销售清单',
                    path: '/salesList',
                }]
            }, {
                name: '审核管理',
                path: '/cashAudit',
                icon: 'el-icon-s-check'
            }, {
                name: '门店管理',
                path: '/house',
                icon: 'el-icon-wallet'
            }, {
                name: '商品管理',
                path: '/shopList',
                icon: 'el-icon-s-goods',
                sub: [{
                    name: '商品列表',
                    path: '/shop',
                },{
				    name: '预售列表',
				    path: '/ysalesList',
				}, {
                    name: '秒杀列表',
                    path: '/seckill',
                }, {
                    name: '拼团列表',
                    path: '/groupWork',
                }, {
                    name: '商品规格',
                    path: '/shopSpec',
                }, {
                    name: '商品分类',
                    path: '/shopType',
                }, {
                    name: '商品场景',
                    path: '/shopScene',
                }, {
                    name: '商品标签',
                    path: '/adminTags',
                }]
            }, {
                name: '订单管理',
                path: '/order',
                icon: 'el-icon-s-order',
                sub: [{
                    name: '商品订单',
                    path: '/order',
                },{
				    name: '预售订单',
				    path: '/ysales',
				},{
                    name: '投诉订单',
                    path: '/complaintOrder',
                }]
            },
            // {
            //     name: '配送管理',
            //     path:'/distribution',
            //     icon: 'el-icon-truck'
            // },
            {
                name: '用户管理',
                path: '/ordinaryUser',
                icon: 'el-icon-s-custom',
                sub: [{
                    name: '普通用户',
                    path: '/ordinaryUser'
                },{
                    name: '会员用户',
                    path: '/memberUser'
                }]
            },
             {
                name: '积分日志',
                path: '/integralLog',
                icon: 'el-icon-coin',
                // sub: [ {
                //     name: '积分日志',
                //     path: '/integralLog',
                // },
                // {
                //     name: '积分商城',
                //     path: '/integralStore',
                // },{
                //     name: '积分订单',
                //     path: '/integralOrder',
                // },
                // {
                //     name: '积分配置',
                //     path: '/integralPz',
                // }
                // ]
            }, {
                name: '推广管理',
                path: '/bonus',
                icon: 'el-icon-postcard',
                sub: [{
                    name: '推广分红',
                    path: '/bonus',
                }, {
                    name: '推广提现',
                    path: '/cash',
                }]
            },
            // {
            //     name:'直播管理',
            //     path:'/wechat',
            //     icon: 'el-icon-s-help',
            // },
            {
                name: '配置管理',
                path: '/config',
                icon: 'el-icon-set-up',
                sub: [{
                    name: '基本配置',
                    path: '/config',
                }, {
                    name: '充值配置',
                    path: '/reCharge',
                }, {
                    name: '优惠券',
                    path: '/coupon',
                }, {
                    name: '会员等级',
                    path: '/memberLevel',
                }, {
                    name: '配送管理',
                    path: '/distribution',
                    // icon: 'el-icon-truck'
                }, {
                    name: '轮播大图',
                    path: '/banner',
                }, {
                    name: '活动管理',
                    path: '/active',
                }, {
                    name: '公告管理',
                    path: '/notice',
                }, {
                    name: '客服管理',
                    path: '/service',
                }]
            }, {
                name: '账号管理',
                path: '/admin',
                icon: 'el-icon-s-tools'
            }]
        }
    },
    //使用watch 监听$router的变化
    watch: {
        $route(to, from) {
            //如果to索引大于from索引,判断为前进状态,反之则为后退状态
            if (to.meta.index > from.meta.index) {
                //设置动画名称
                this.transitionName = 'slide-left';
            } else {
                this.transitionName = 'slide-right';
            }

            axios.post(window.domain + '/admin.statistics/news').then(response => {
                if (response.data.code == 200) this.$root.auditCount = response.data.data
            })
        }
    },

    //导航守卫
    beforeRouteEnter(to, from, next) {
        // Promise.all 會等到數組內的 Promise 都 resolve 後才會繼續跑(then)
        Promise.all([
            axios.post(window.domain + '/admin.statistics/news')
        ]).then(result => next(vm => {
            if (result[0].data.code == 200) vm.$root.auditCount = result[0].data.data;
        }))
    },
    mounted: function () {
        backPlatformApi();
        this.adminInfo = localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : this.adminInfo;
    },
    methods: {
        //nav跳转
        navTo: function (path) {
            this.$router.push(path);
        },

        handleCollapse: function () {
            this.isCollapse = !this.isCollapse;
        },

        // 退出登录
        loginOut: function () {
            var api = "/login/logout";
            this.http.post(api).then(() => {
                localStorage.clear();
                this.$router.push("/");
                // clearInterval(this.timer);
                // this.timer = null;
            });
        }
    }
}
</script>
<style media="screen">
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all 500ms;
    position: absolute;
}

.slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 200);
}

.slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 200);
}
</style>
